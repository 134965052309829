<template>
    <div class="content">
        <div class="logo">
            <img src="@/assets/images/packet.png">
        </div>
        <div class="hongbao">
            <div class="price">
                <p class="balance">
                    <span>￥</span>
                    <span>{{ parseFloat(goods.balance).toFixed(0) }}</span>
                </p>
                <p class="point">
                    <span>{{ goods.name }}</span>
                </p>
            </div>
            <div class="coupon_num  font-size-tag">{{ goods.point }}积分</div>

            <div class="coupon_right">
                <div class="coupon_num">剩余{{ goods.current_stock?goods.current_stock:'0' }}个</div>
                <div class="coupon_btn" @click="buy">{{ goods.current_stock&&goods.current_stock>0?"立即兑换":'库存不足' }}</div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from "vuex"
    import { onTip } from "@/utils/tips"
    import { create, payment } from "@/api/promotion/point"

    export default {
        props: {
            goods: {
                required: true,
                type: Object
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters(["defaultGoodsImage", "storeWithoutAuthority"])
        },
        methods: {
            async buy() {
                if(this.goods.current_stock==0||(!this.goods.current_stock)){
                    this.$message.error("库存不足")
                    return;
                }
                const data = {
                    id: this.goods.id,
                    num: 1,
                    sku_id: 0,
                    buyer_message: '',
                    delivery: "{}"
                }
                const create_res = await create(data)
                if (create_res.code === 0) {
                    this.$message.success("兑换成功")
                    this.$emit("updata");
                } else {
                    this.$message.error(create_res.message)
                }
                
            }
        }
    }
</script>
<style lang="scss" scoped>
    .content {
        background: linear-gradient(90deg, #FF886F 0%, #E74E42 100%);
        border-radius: 14px;
        width: 320px;
        display: flex;
        padding: 16px;
        gap: 12px;

        .logo {
            width: 108px;
            height: 108px;
            background: #FFFFFF;
            border-radius: 9px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 80px;
            }
        }

        .hongbao {

            color: #FFFCE7;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            .price {
                display: flex;
                align-items: baseline;
                gap: 9px;

                .balance {
                    font-weight: 600;
                    font-size: 33px;
                    letter-spacing: 3px;
                    line-height: 46px;
                }

                .point {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                }

            }

            .coupon_right {
                color: #FFFCE7;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .coupon_num {
                    font-size: 14px;
                    line-height: 20px;
                }

                .coupon_btn {
                    cursor: pointer;
                    font-size: 14px;
                    background: linear-gradient(180deg, #FFEBDB 0%, #EFB377 100%);
                    line-height: 28px;
                    padding: 0 12px;
                    border-radius: 14px;
                    color: #E74F43;
                    font-weight: 500;
                    font-style: normal;
                }
            }
        }
    }
</style>
