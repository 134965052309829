<template>
    <div class="coupon">
        <div class="coupon_left" :style="{'background-image': `url(${require('@/assets/images/coupon_bg2.png')})`}">
            <p class="price">
                <template v-if="goods.coupon_type == 'reward'">￥{{ parseFloat(goods.money) }}</template>
                <template v-if="goods.coupon_type == 'discount'">
                    <template v-if="goods.coupon_type == 'discount'">{{ parseFloat(goods.discount) }}折</template>
                </template>
            </p>
            <p class="info">{{ goods.at_least == 0 ? '无门槛优惠券' : '满' + parseFloat(goods.at_least).toFixed(0) + '可用' }}</p>
            <p class="info">{{ goods.goods_type == 2 ? '指定券' : '全场券' }}</p>
        </div>
        <div class="coupon_right">
            <div class="wrapper">
                <p class="title">{{goods.name}}</p>
                <p class="info">{{ goods.point }}积分</p>
                <p class="info">领取之日10日内有效</p>
            </div>
           <div style="text-align: right">
               <div class="coupon_btn" @click="buy">{{ hasBuy ? "查看" : "立即兑换" }}</div>
           </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { onTip } from "@/utils/tips"
    import { create } from "@/api/promotion/point"

    export default {

        props: {
            goods: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                hasBuy: false
            }
        },
        computed: {
            ...mapGetters(["defaultGoodsImage", "storeWithoutAuthority"])
        },
        methods: {
            async buy() {
                if (this.hasBuy) {
                    this.$router.push('/member/my_coupon')
                    return
                }
                const data = {
                    id: this.goods.id,
                    num: 1,
                    sku_id: 0,
                    buyer_message: '',
                    delivery: "{}"
                }
                const create_res = await create(data)
                if (create_res.code === 0) {
                    this.$message.success("兑换成功")
                    this.hasBuy = true
                } else {
                    this.$message.error(create_res.message)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .coupon {
        width: 352px;
        background: #FEF2F0;
        border-radius: 14px;
        display: flex;
    }
    .coupon_right {
        flex: 1;
        padding: 18px 16px 16px 19px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 18px;
            color: #424242;
            line-height: 25px;
            font-weight: 600;
            margin-bottom: 5px;
        }
        .info {
            font-weight: 400;
            font-size: 12px;
            color: #424242;
            line-height: 17px;
            margin-bottom: 5px;

        }
        .coupon_btn {
            display: inline-block;
            background: linear-gradient( 180deg, #F75939 0%, #F74842 100%);
            border-radius: 14px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 4px 12px;
            color: #FFFCE7;
            cursor: pointer;
        }
    }
    .coupon_left {
        color: #FFFCE7;
        width: 142px;
        height: 138px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .price {
            font-weight: 600;
            font-size: 45px;
            letter-spacing: 4px;
            line-height: 63px;
            margin-bottom: 10px;
        }
        .info {
            color: #FFFCE7;
            font-size: 12px;
            line-height: 17px;
            font-weight: 500;
            margin-bottom: 4px;
        }
    }
</style>
