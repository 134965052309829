<script>
    import Goods from "./promotion/goods.vue";
    import Coupon from "./promotion/coupon.vue";
    import Packet from "./promotion/packet.vue";

    export default {
        name: "item",
        components: {Goods, Coupon, Packet},
        props: {
            goods: {
                required: true,
                type: Object
            },
            type: {
                required: true,
                type: Number
            }
        },
        methods: {
            getComponent() {
                const a = [undefined,Goods, Coupon, Packet]
                return a[this.type]
            },
            updata(){
                this.$emit("itemupdata")
            }
        }
    }
</script>

<template>
   <component :is="getComponent()" :goods="goods" @updata='updata'></component>
</template>
