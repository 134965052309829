<template>
    <div class="goods-wrapper">
        <goods-list-item :goods="goodsAdpter(goods)" :up-down-layout="true" @click="toDetail">
            <template #price>
                <div style="color: #EE443F;font-size: 16px;margin-right: 6px">
                    <span>{{ goods.point }}积分</span>
                    <template v-if="Number(goods.price) > 0 && goods.pay_type > 0">
                        <span> + </span>
                        <span style="font-size: 14px">￥</span>
                        <span>{{ goods.price }}</span>
                    </template>
                </div>
            </template>
            <template #addCart>
                <div class="btn" @click.stop="buy">立即兑换</div>
            </template>
        </goods-list-item>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { onTip } from "@/utils/tips"
    import GoodsListItem from "@/components/goods-list-item/goods-list-item.vue"
    import AddCart from "@/components/goods-list-item/add-cart.vue"

    export default {
        components: { AddCart, GoodsListItem },
        props: {
            goods: {
                required: true,
                type: Object
            }
        },
        mounted() {
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters(["defaultGoodsImage", "storeWithoutAuthority"])

        },
        methods: {
            toDetail() {

            },
            buy() {
                this.$router.push("/point/payment?id=" + this.goods.id)
            },
            goodsAdpter() {
                return {
                    ...this.goods,
                    attr: this.goods.goods_attr,
                    goods_name: this.goods.g_name,
                    goods_image: this.goods.image,
                    sale_control: {
                        is_member_by: true,
                        is_sale_control: false,
                        not_buy_msg: "",
                        sale_control_id: 0,
                        sale_control_name: "",
                        suggest_price: -1,
                        view_price: true
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .goods-wrapper {
        width: 200px;
        padding: 22px 12px 14px 12px;
        transition: all 0.2s linear;
        border: 1px solid #F0F0F0;
        cursor: pointer;

        .btn {
            float: right;
            display: inline-block;
            padding: 0 12px;
            height: 27px;
            background: linear-gradient(44deg, #F17F3A 0%, rgba(240, 120, 82, 0.07) 28%, rgba(240, 115, 100, 0) 49%, rgba(247, 92, 111, 0.47) 73%, #FF427C 100%), #FF638B;
            border-radius: 4px;
            line-height: 27px;
            text-align: center;
            font-size: 12px;
            color: #FFFFFF;
        }
    }
</style>
